import moment from 'moment'

export const MomentMixin = {
  methods: {
    momentDate(dateString, format) {
      return moment(dateString).locale('it').format(format ? format : 'DD/MM/YYYY')
    },

    formatFE(date) {
      return date ? moment(date).format('DD/MM/YYYY') : null
    },

    formatDate(date) {
      if (date) {
        return moment(date).format('YYYY-MM-DD')
      }
      return null
    },

    getSimpleDay() {
      return moment().format()
    },

    simpleFormat(date) {
      if (!date) return null
      return moment(date).format()
    },

    momentToDate(date) {
      if (!date) return null
      return moment(date).toDate()
    },

    momentToDateGetTime(date) {
      if (!date) return null
      return moment(date).toDate().getTime()
    },

    getSubtractedDate(number, type) {
      let num = number
      let by = type
      if (!number) num = 1
      if (!type) by = 'days'
      return this.simpleFormat(moment().subtract(num, by))
    },

    handleError(error) {
      try {
        if (!this.$store.state.clientVersion.clientVersionShowModal) {
          alert(error)
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e)
      }
    },

    // fromDate format => Wed Apr 13 2022 16:59:08 GMT-0700 (Mountain Standard Time)
    getEndDate(numDays, fromDate) {
      const days = numDays
      const date = fromDate ? fromDate : new Date()
      const last = new Date(date.getTime() + days * 24 * 60 * 60 * 1000)
      const day = last.getDate()
      const month = last.getMonth() + 1
      const year = last.getFullYear()
      return `${year}-${month}-${day}`
    }
  }
}
