import conf from './conf'

const baseURL = conf.value('VUE_APP_REPORTS_URL')

// FIXME clean this methods
const _isObj = function(a) {
  return !!a && a.constructor === Object
}

// FIXME clean this methods
const _st = function(z, g) {
  return '' + (g !== '' ? '[' : '') + z + (g !== '' ? ']' : '')
}

// FIXME clean this methods
const _fromObject = function(params, skipobjects, prefix) {
  if (skipobjects === void 0) {
    skipobjects = false
  }

  if (prefix === void 0) {
    prefix = ''
  }

  let result = ''
  if (typeof (params) != 'object') {
    return prefix + '=' + encodeURIComponent(params) + '&'
  }

  for (const param in params) {
    const c = '' + prefix + _st(param, prefix)
    if (_isObj(params[param]) && !skipobjects) {
      result += _fromObject(params[param], false, '' + c)
    } else if (Array.isArray(params[param]) && !skipobjects) {
      params[param].forEach(function(item) {
        result += _fromObject(item, false, c)
      })
    } else {
      result += c + '=' + encodeURIComponent(params[param]) + '&'
    }
  }

  return result
}

const _generateReport = function(params) {
  window.open(baseURL + 'reports?' + _fromObject(params))
}

const _print = function(title, mode, dateFrom = null, dateTo = null, employeeId = null, status = null, pileId = null, plantId = null, pileType = null, ddtId = null, sector = null) {
  window.open(
    baseURL + 'reports' +
    '?title=' + title +
    '&mode=' + mode +
    (dateFrom ? '&dateFrom=' + dateFrom : '') +
    (dateTo ? '&dateTo=' + dateTo : '') +
    (employeeId ? '&employeeId=' + employeeId : '') +
    (status ? '&status=' + status : '') +
    (pileId ? '&pileId=' + pileId : '') +
    (plantId ? '&plantId=' + plantId : '') +
    (pileType ? '&pileType=' + pileType : '') +
    (ddtId ? '&ddtId=' + ddtId : '') +
    (sector ? '&sector=' + sector : '')
  )
}

const reportsApi = {
  plan: function(date) {
    _print('Plan', 'pdf', date)
  },

  employeeDailyReport: function(employeeId, dateFrom, dateTo) {
    _print('EmployeeDailyReport', 'xls', dateFrom, dateTo, employeeId)
  },

  printReport: function(title, dateFrom, dateTo, format) {
    _print(title, format, dateFrom, dateTo)
  },

  // FIXME @Deprecated
  activityRequestReport: function(dateFrom, dateTo, employeeId, status) {
    _print('ActivityRequestOld', 'xls', dateFrom, dateTo, employeeId, status)
  },

  activityRequests: function(params) {
    params.title = 'ActivityRequest'
    params.mode = 'xls'
    _generateReport(params)
  },

  pileMovementsReport: function(pileId, plantId, pileType) {
    _print('PileMovement', 'xls', null, null, null, null, pileId, plantId, pileType)
  },

  ddt: function(ddtId) {
    _print('Ddt', 'pdf', null, null, null, null, null, null, null, ddtId)
  },

  eventStore: function() {
    _print('EventStore', 'xls', null, null, null, null, null, null, null, null, 'Planner')
  }
}

export default reportsApi
