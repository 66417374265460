<template>
  <main class="page-overflow ma-4">


    <!-- page header -->
    <div class="page-header-3 mx-2">

      <!-- page header: filters -->
      <div class="page-header-filters">
        <!-- go back to global report -->
        <h6 class="main--text" style="cursor:pointer" @click="$router.push('/time-reports')">
          <v-icon :size="10">mdi-arrow-left</v-icon>
          {{ translations.goTo }} {{ translations.allEmployeeReportShort }}
        </h6>

        <!-- empty filter -->
        <div></div>

        <!-- employee filter -->
        <v-autocomplete :items="employeeList" item-text="name" v-model="selectedEmployee" :placeholder="translations.filterForEmployee"
                        :search-input.sync="employeeToSearch" :loading="$apollo.queries.employees.loading"
                        @change="changedSelectedEmployee" return-object
                        clearable dense hide-details autocomplete="off">
          <template v-slot:append-item>
            <v-row class="ma-0" justify="center" v-if="hasNextEmployees && !$apollo.queries.employees.loading">
              <v-progress-circular v-intersect.quiet="onIntersect" indeterminate color="main" :size="20"></v-progress-circular>
            </v-row>
          </template>
          <template v-slot:no-data>
            <v-list-item>
              {{ translations.noEmployeesFoundWThatName }}
            </v-list-item>
          </template>
        </v-autocomplete>
      </div>

      <!-- page header: title -->
      <div class="all-center">
        <time-report-date-header :displayType="displayType" :displayItem="displayItem"
                                 :today="today" :todayDate="todayDateNum" :todayMonth="todayMonthNum" :todayYear="todayYearNum"
                                 :maxDateWeekly="maxDateWeekly" :headersHaveMaxDate="headersHaveMaxDate"
                                 @previous="previousHandler" @next="nextHandler" @set-weekly="setWeeklyDisplay" @set-monthly="setMonthlyDisplay" @set-custom="setCustomDisplay"/>
      </div>

      <!-- page header: right -->
      <div class="all-center justify-space-around">
        <div class="all-center" style="width: 20%"></div>

        <!-- export report toolbar -->
        <div class="all-center" style="width: 60%">
          <v-btn icon dark color="main" @click="printVehicles" v-if="false">
            <v-icon>mdi-van-utility</v-icon>
          </v-btn>

          <v-btn v-if="hasSelectedEmployee" icon dark color="main" @click="printEmployeeDailyReport">
            <v-icon>mdi-printer</v-icon>
          </v-btn>
        </div>
      </div>
    </div>


    <!-- main content -->
    <v-card class="ma-1">
      <div v-if="!selectedEmployee.id" class="page-content all-center-column">
        <h2 class="main--text mb-6">{{ translations.selectEmployeeForReport }}</h2>
        <select-employee-svg width="500px"></select-employee-svg>
      </div>

      <v-data-table v-else :headers="allHeaders" :items="items" :items-per-page="-1"
                    class="page-table-no-footer emp-time-table" fixed-header hide-default-footer
                    disable-sort :loading="loadingReport">

        <template v-for="(header, index) in allHeaders" v-slot:[`item.${header.value}`]="{ item }">
          <div v-if="header.value === 'usedVan' || header.value === 'nightHours'" :key="`${header.value}-${index}`" style="text-align: center; min-width: 40px">
            <v-chip v-if="item[header.value] === true" color="#E7F5E9" small label>
              <div class="d-flex align-center">
                <v-icon x-small class="mr-1" color="#2A6A2D">
                  mdi-check
                </v-icon>
                <span style="font-size: .875rem; color: #2A6A2D">{{ translations.yes }}</span>
              </div>
            </v-chip>

            <v-chip v-else-if="item[header.value] === false" color="#FFEAEC" small label>
              <div class="d-flex align-center">
                <v-icon x-small class="mr-1" color="#D60011">
                  mdi-close
                </v-icon>
                <span style="font-size: .875rem; color: #D60011">
                {{ translations.no }}
              </span>
              </div>
            </v-chip>

            <span v-else-if="item[header.value] !== 'hide'">-</span>
          </div>

          <div v-else :key="`other-${index}`" style="text-align: center; min-width: 80px">
            <span>{{ item[header.value] ? item[header.value] : '-' }}</span>
          </div>
        </template>
      </v-data-table>
    </v-card>
  </main>
</template>


<script>
import moment from 'moment'
import { months, translations } from '@/utils/common'
import { MomentMixin } from '@/utils/mixins/MomentMixin.js'

import employeesQuery from '@/graphql/Employees.gql'
import dailyReportsQuery from '@/graphql/DailyReports.gql'
import clockInPairsQuery from '@/graphql/ClockInPairs.gql'

import TimeReportDateHeader from '@/components/TimeReportDateHeader.vue'
import SelectEmployeeSvg from '@/assets/SelectEmployeeSvg.vue'

import reportsApi from '@/utils/api/reportsApi'

export default {
  components: {
    TimeReportDateHeader,
    SelectEmployeeSvg
  },

  apollo: {
    employees: {
      query: employeesQuery,
      variables() {
        return {
          page: 1,
          pageSize: 10,
          sort: 'name',
          order: 'asc',
          name: this.employeeToSearch
        }
      },
      skip() {
        return this.$route?.query?.id ? true : false
      }
    }
  },

  data: () => ({
    employeeToSearch: null,
    employeePage: 1,
    employeeList: [],
    hasNextEmployees: true,
    reports: [],
    clockInPairs: [],
    dates: [],
    items: [],
    startDate: null,
    endDate: null,
    displayType: 'monthly',
    displayingMonth: {
      text: null,
      value: null
    },
    displayingWeek: {},
    displayingCustom: {},
    todayDateNum: null,
    todayMonthNum: null,
    todayYearNum: null,
    selectedEmployee: {},
    loadingReport: false
  }),

  async created() {
    // set data
    const queryDate = this.$route?.query?.date
    let d
    if (queryDate) {
      d = new Date()
    } else {
      d = new Date()
    }

    const day = d.getDate()
    const month = d.getMonth()
    const year = d.getFullYear()
    this.todayDateNum = day
    this.todayMonthNum = month
    this.todayYearNum = year
    this.currentMonth = month
    this.displayingMonth.text = `${this.months[month]} ${year}`
    this.displayingMonth.value = month
    this.currentYear = year
    this.dates = await this.getMonthlyDates(month, year)

    // load employee (if exists)
    const employeeId = this.$route?.query?.employeeId
    if (employeeId) {
      await this.loadQueryEmployee(employeeId)
      await this.getReport()
    }
  },

  mixins: [MomentMixin],

  methods: {
    async loadQueryEmployee(id) {
      try {
        const res = await this.$apollo.query({
          query: employeesQuery,
          variables: {
            page: 1,
            pageSize: 1,
            sort: 'name',
            order: 'asc',
            id: id
          }
        })

        this.selectedEmployee = res.data.employees.content[0]
        this.employeeList = res.data.employees.content
        this.hasNextEmployees = res.data.employees.hasNext
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e)
      }
    },

    async onIntersect(entries, observer, isIntersecting) {
      if (isIntersecting) {
        this.fetchMoreEmployees()
      }
    },

    async fetchMoreEmployees() {
      this.employeePage++

      const res = await this.$apollo.query({
        query: employeesQuery,
        variables: {
          page: this.employeePage,
          pageSize: 10,
          sort: 'name',
          order: 'asc',
          name: this.employeeToSearch
        }
      })
      this.hasNextEmployees = res.data.employees.hasNext
      this.employeeList.push(...res.data.employees.content)
    },

    changedSelectedEmployee(employee) {
      if (!employee) {
        this.selectedEmployee = {}
      } else {
        this.getReport()
      }
    },

    loadItems() {
      if (!this.dates?.length) return []

      // total items
      const total = {
        usedVan: false,
        nightHours: false,
        totalHours: 0,
        ordinaryHours: 0,
        extraOrdinaryHours: 0,
        layoffHours: 0,
        allowance: {}
      }

      this.$store.getters.employeeStatuses.forEach(es => {
        total[es.keyCode] = 0
      })

      const dateItems = []
      this.dates.forEach(date => {
        //Check date report
        const dateToCheck = date.value
        const foundReport = this.reports.find(report => report.dateStr === dateToCheck)
        const foundClockInPairs = this.clockInPairs.find(clockInPairList => clockInPairList.dateStr === dateToCheck)

        //return if no report found
        if (!foundReport) {
          dateItems.push({ item: date.text })
        } else {
          const {
            totalHours,
            ordinaryHours,
            extraOrdinaryHours,
            layoffHours,
            allowance,
            van,
            activitiesHours,
            nightHours
          } = foundReport.report;
          //sum total hours
          (total.totalHours += totalHours), (total.ordinaryHours += ordinaryHours)
          total.extraOrdinaryHours += extraOrdinaryHours
          total.layoffHours += layoffHours
          if (allowance) {
            if (total.allowance[allowance]) {
              total.allowance[allowance]++
            } else {
              total.allowance[allowance] = 1
            }
          }

          const item = {
            item: date.text,
            clockIns: foundClockInPairs ? this.convertClockInPairsToString(foundClockInPairs.clockInPairs) : '',
            totalHours: totalHours,
            ordinaryHours: ordinaryHours,
            extraOrdinaryHours: extraOrdinaryHours,
            layoffHours: layoffHours,
            allowance: allowance,
            usedVan: van,
            nightHours: nightHours
          }

          this.$store.getters.employeeStatuses.forEach(es => {
            if (activitiesHours) {
              let hours = activitiesHours[es.keyCode] ? activitiesHours[es.keyCode] : 0
              item[es.keyCode] = hours
              total[es.keyCode] += hours
            }
          })

          if (van) {
            total.usedVan = true
          }
          if (nightHours) {
            total.nightHours = true
          }

          dateItems.push(item)
        }
      })

      let totalAllowanceStr = ''
      for (let allowance in total.allowance) {
        totalAllowanceStr += allowance + ': ' + total.allowance[allowance] + ', '
      }
      if (totalAllowanceStr.length > 2) {
        totalAllowanceStr = totalAllowanceStr.substr(0, totalAllowanceStr.length - 2)
      }
      total.allowance = totalAllowanceStr

      this.items = [
        ...dateItems,
        {
          item: this.translations.total,
          ...total
        }]
    },

    async getReport() {
      this.loadingReport = true
      try {
        const { id } = this.selectedEmployee
        let res = await this.$apollo.query({
          query: dailyReportsQuery,
          variables: {
            dateStrFrom: this.startDate ? this.formatDate(this.startDate) : null,
            dateStrTo: this.endDate ? this.formatDate(this.endDate) : null,
            employee: { id }
          },
          fetchPolicy: 'network-only'
        })
        this.reports = res.data.dailyReports

        res = await this.$apollo.query({
          query: clockInPairsQuery,
          variables: {
            dateStrFrom: this.startDate ? this.formatDate(this.startDate) : null,
            dateStrTo: this.endDate ? this.formatDate(this.endDate) : null,
            employeeId: id
          },
          fetchPolicy: 'network-only'
        })
        this.clockInPairs = res.data.clockInPairs
        this.loadItems()

        this.loadingReport = false
      } catch (e) {
        this.loadingReport = false

        // eslint-disable-next-line no-console
        console.log(e)
      }
    },

    async previousHandler() {
      //If displaying monthly results
      if (this.displayType === 'monthly') {
        if (this.currentMonth === 0) {
          this.currentMonth = 11
          this.currentYear--
        } else {
          this.currentMonth--
        }
        this.dates = await this.getMonthlyDates(this.currentMonth, this.currentYear)
        this.displayingMonth.text = `${this.months[this.currentMonth]} ${this.currentYear} `
        this.displayingMonth.value = this.currentMonth
        this.getReport()
      } else if (this.displayType === 'weekly') {
        const dateToChange = moment(this.displayingWeek.value.startDate.slice(0, 10))
          .subtract(6, 'days')
          .format('YYYY-MM-DD')

        await this.setWeeklyDisplay(dateToChange)
      }
    },

    async nextHandler() {
      //If displaying monthly results
      if (this.displayType === 'monthly') {
        if (this.currentMonth === 11) {
          this.currentMonth = 0
          this.currentYear++
        } else {
          this.currentMonth++
        }
        this.dates = await this.getMonthlyDates(this.currentMonth, this.currentYear)
        this.displayingMonth.text = `${this.months[this.currentMonth]} ${this.currentYear} `
        this.displayingMonth.value = this.currentMonth
        this.getReport()
      } else if (this.displayType === 'weekly') {
        const dateToChange = this.displayingWeek.value.endDate.slice(0, 10)

        await this.setWeeklyDisplay(dateToChange)
      }
    },

    // <================ Monthly Display =============>
    getMonthlyDates(month, year) {
      let date = new Date(year, month, 1)
      let days = []

      while (date.getMonth() === month) {
        const dateObj = {
          text: this.momentDate(date, 'DD/MM/YYYY'),
          value: this.formatDate(date)
        }
        days.push(dateObj)
        date.setDate(date.getDate() + 1)
      }

      this.startDate = this.formatDate(days[0].value)
      this.endDate = this.formatDate(days[days.length - 1].value)
      return days
    },

    async setMonthlyDisplay() {
      this.displayType = 'monthly'
      this.displayingMonth.text = `${this.months[this.todayMonthNum]} ${this.currentYear} `
      this.displayingMonth.value = this.todayMonthNum
      this.dates = await this.getMonthlyDates(this.todayMonthNum, this.todayYearNum)
      this.getReport()
    },

    // <================ Monthly Display End =============>

    // <================ Weekly Display Start =============>
    async setWeeklyDisplay(date) {
      const startDateText = this.momentDate(date, 'DD/MM/YYYY')

      const dateArr = date.split('-')
      const startDayNum = dateArr[2]
      const startMonth = dateArr[1] - 1
      const year = dateArr[0]
      //used for the headers
      const endDateText = moment(date).add(6, 'days')
      const endDayNum = endDateText.format('DD')

      this.startDate = this.formatDate(date)
      //used for display
      const endDateUnFormatted = endDateText.format('YYYY-MM-DD')
      this.endDate = endDateUnFormatted
      let endDateFormatted = this.momentDate(endDateUnFormatted, 'DD/MM/YYYY')

      this.dates = await this.setWeeklyHeaders(startDayNum, endDayNum, startMonth, year, 7)

      endDateFormatted = this.momentDate(this.dates[this.dates.length - 1].value, 'DD/MM/YYYY')

      this.displayType = 'weekly'
      this.displayingWeek = {
        text: `${startDateText} - ${endDateFormatted}`,
        value: {
          startDate: this.startDate,
          endDate: this.endDate
        }
      }
      this.getReport()
    },

    setWeeklyHeaders(startDay, endDay, month, year, totalDays) {
      let date = new Date(year, month, startDay)
      let iterations = 0
      let days = []

      this.startDate = this.formatDate(date)
      while (iterations < totalDays) {
        const dateObj = {
          text: this.momentDate(date, 'DD/MM/YYYY'),
          value: this.formatDate(date)
        }
        days.push(dateObj)
        date.setDate(date.getDate() + 1)
        iterations++
      }

      return days
    },
    //<================ Weekly Display End=============>

    //<=====================Custom Display Start =====================>
    setCustomHeaders(startDate, endDate) {
      let date = new Date(startDate)
      const lastDate = new Date(endDate)
      let days = []

      while (date <= lastDate) {
        const dateObj = {
          text: this.momentDate(date, 'DD/MM/YYYY'),
          value: this.formatDate(date)
        }
        days.push(dateObj)
        date.setDate(date.getDate() + 1)
      }

      return days
    },

    async setCustomDisplay(dates) {
      const { from, to } = dates
      const startDateText = this.momentDate(from, 'DD/MM/YYYY')

      this.startDate = from
      this.endDate = to
      this.dates = await this.setCustomHeaders(from, to)

      let endDateFormatted = this.momentDate(this.dates[this.dates.length - 1].value, 'DD/MM/YYYY')

      this.displayType = 'custom'
      this.displayingCustom = {
        text: `${startDateText} - ${endDateFormatted}`,
        value: {
          startDate: this.startDate,
          endDate: this.endDate
        }
      }

      this.getReport()
    },
    //<======================= Custom Display End ====================>

    printEmployeeDailyReport() {
      const dateFrom = this.dates[0].value
      const dateTo = this.dates[this.dates.length - 1].value

      reportsApi.employeeDailyReport(this.selectedEmployee.id, dateFrom, dateTo)
    },

    printVehicles() {
      const dateFrom = this.dates[0].value
      const dateTo = this.dates[this.dates.length - 1].value
      reportsApi.vehicles(dateFrom, dateTo)
    },

    convertClockInPairsToString(clockInPairs) {
      let str = ''
      clockInPairs.forEach(clockInPair => {
        const inTimeStr = clockInPair.clockIn?.updatedTimestampStr.split(' ')[1]
        const outTimeStr = clockInPair.clockOut?.updatedTimestampStr.split(' ')[1]
        if (outTimeStr) str += inTimeStr + '-' + outTimeStr + '\n'
        else str += inTimeStr + '-' + '\n'
      })
      return str
    }
  },

  computed: {
    translations: () => translations,

    months: () => months,

    allHeaders() {
      const headers = [
        { text: '', value: 'item' },
        {
          text: this.translations.clockInTitle,
          value: 'clockIns'
        },
        {
          text: this.translations.totalHours,
          value: 'totalHours'
        },
        {
          text: this.translations.ordinaryHours,
          value: 'ordinaryHours'
        },
        {
          text: this.translations.extraOrdinaryHours,
          value: 'extraOrdinaryHours'
        },
        {
          text: this.translations.layoffHours,
          value: 'layoffHours'
        },
        {
          text: this.translations.allowance,
          value: 'allowance'
        },
        {
          text: this.translations.usedVan,
          value: 'usedVan'
        },
        {
          text: this.translations.nightHours,
          value: 'nightHours'
        }
      ]

      this.$store.getters.employeeStatuses.forEach(es =>
        headers.push({
          text: es.description + ' (' + this.translations.hours + ')',
          value: es.keyCode
        })
      )
      return headers
    },

    yesterday() {
      return moment().subtract(1, 'day').format('YYYY-MM-DD')
    },

    today() {
      return this.getSimpleDay().slice(0, 10)
    },

    displayItem() {
      if (this.displayType === 'monthly') return this.displayingMonth
      else if (this.displayType === 'weekly') return this.displayingWeek
      else return this.displayingCustom
    },

    maxDateWeekly() {
      return moment().subtract(6, 'days').format('YYYY-MM-DD')
    },

    headersHaveMaxDate() {
      return false
    },

    hasSelectedEmployee() {
      return this.selectedEmployee?.id ? true : false
    }
  },

  watch: {
    employeeToSearch() {
      this.employeePage = 1
    },

    employees() {
      this.employeeList = this.employees.content
      this.hasNextEmployees = this.employees.hasNext
    }
  }
}
</script>


<style>
div.emp-time-table > div > table > thead > tr > th:first-child,
div.emp-time-table > div > table > tbody > tr > td:first-child {
  position: sticky;
  left: 0;
  background-color: #f4f4f4;
  z-index: 3;
}

div.emp-time-table > div > table > tbody > tr > td:first-child {
  z-index: 2;
}

div.emp-time-table > div > table > tbody > tr:last-child {
  position: sticky;
  bottom: 0;
  background-color: #f4f4f4;
  z-index: 3;
}

div.emp-time-table > div > table > thead > tr > th:not(:first-child) {
  text-align: center !important;
}

/* Scroll bar */
div.emp-time-table > div.v-data-table__wrapper::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  border-radius: 5px;
}

/* Track */
div.emp-time-table > div.v-data-table__wrapper::-webkit-scrollbar-track {
  background: #f6f8fa;
  border-radius: 5px;
}

/* Handle */
div.emp-time-table > div.v-data-table__wrapper::-webkit-scrollbar-thumb {
  background: #d0cfcf;
  border-radius: 5px;
}

/* Handle on hover */
div.emp-time-table > div.v-data-table__wrapper::-webkit-scrollbar-thumb:hover {
  background: #888;
  border-radius: 5px;
}

div.emp-time-table > div > table {
  white-space: pre-line;
}
</style>
